import React from "react"

import "./style.scss"
import WebpImage from "../WebpImage"

const ProductWithHubble = ({ items, type }) => {
  return (
    <div className="product-with-hubble" data-type={type}>
      <div className="grid-section">
        <h3 className="text h3 heading">See the Hubble Difference</h3>
        <div className="values-container">
          {items &&
            items.map(({ image, title, description,alt }, index) => (
              <div className="prop" key={index}>
                <WebpImage fileName={image} alt={alt} />
                <h4 className="text h7 neue-bold title">{title}</h4>
                <p className="text h7 neue description">{description}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default ProductWithHubble
